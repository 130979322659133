import React from 'react'
import Layout from "../../components/Layout";


export default class CategoriesIndexPage extends React.Component {
  render() {
    const {location} = this.props;
    return (
        <Layout location={location}>

        </Layout>
    )
  }
}
